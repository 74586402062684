<script setup lang="ts">
const { t } = useI18n({
  useScope: "local",
});
</script>

<template>
  <tw-ui-message-box type="warning" class="box">
    <p>{{ t("email-not-confirmed") }}</p>
  </tw-ui-message-box>
</template>

<style scoped lang="scss">
.box {
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
}
</style>

<i18n lang="json">
{
  "de": {
    "email-not-confirmed": "Deine E-Mail Adresse ist noch nicht registriert. Wir haben dir soeben eine E-Mail geschickt, worin du deine E-Mail Adresse bestätigen kannst."
  },
  "en": {
    "email-not-confirmed": "Your e-mail address is not yet registered. We have just sent you an e-mail in which you can confirm your e-mail address."
  }
}
</i18n>
